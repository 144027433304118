<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="40%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- cluster -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Pilih Cluster"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="cluster_id"
                        has-feedback>
                        <FilterCluster
                            style="width:100%"
                            :disabled="form.readOnly"
                            v-model:value="form.cluster_id"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- regional -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Pilih Regional"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="regional_id"
                        has-feedback>
                        <FilterRegional
                            style="width:100%"
                            :disabled="form.readOnly"
                            :mode="null"
                            v-model:value="form.regional_id"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- provinsi -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Pilih Provinsi"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="provinsi_id"
                        has-feedback>
                        <FilterProvince
                            style="width:100%"
                            :disabled="form.readOnly"
                            v-model:region="form.regional_id"
                            v-model:value="form.provinsi_id"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- min -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Nilai Min"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="min"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.min"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- max -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Nilai Max"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="max"
                        has-feedback>
                        <AInput
                            placeholder="number"
                            type="number"
                            :min="0"
                            v-model:value="form.max"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- status -->
            <ARow class="form-row">
                <ACol :md="24">
                    <AFormItem
                        label="Status"
                        label-align="left"
                        :label-col="{ md: { span: 8 } }"
                        :wrapper-col="{ md: { span: 24 - 8 } }"
                        required
                        name="status"
                        has-feedback>
                        <ARadioGroup
                            v-model:value="form.status"
                            style="width:100%">
                            <a-radio :value="true">Aktif</a-radio>
                            <a-radio :value="false">Non Aktif</a-radio>
                        </ARadioGroup>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import Form from 'vform'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterCluster from '@/components/filter/FilterCluster'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterRegional,
        FilterProvince,
        FilterCluster,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            regional_id: [],
            provinsi_id: [],
            cluster_id: [],
            min: null,
            max: null,
            status: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Konfigurasi Cluster',
            endpoint: {
                create: '/api/cluster/config',
                update: `/api/cluster/config/update/${props.item.id}`,
                show: `/api/cluster/config/show/${props.item.id}`,
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                regional_id: [
                    {
                        required: true,
                        message: 'Regional tidak boleh kosong!',
                    },
                ],
                provinsi_id: [
                    {
                        required: true,
                        message: 'Provinsi tidak boleh kosong!',
                    },
                ],
                cluster_id: [
                    {
                        required: true,
                        message: 'Cluster tidak boleh kosong!',
                    },
                ],
                min: [
                    {
                        required: true,
                        message: 'Min tidak boleh kosong!',
                    },
                ],
                max: [
                    {
                        required: true,
                        message: 'Max tidak boleh kosong!',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: 'Status tidak boleh kosong!',
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(state.endpoint.create)
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data.error) {
                            message.error(data.error)
                        }

                        if (data.errors) {
                            state.errors = data.errors
                        }
                    })
            } else {
                form.post(state.endpoint.update)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data.error) {
                            message.error(data.error)
                        }

                        if (data.errors) {
                            state.errors = data.errors
                        }
                    })
            }

        }

        const fetchById = () => {
            apiClient.get(state.endpoint.show)
                .then(({ data }) => {
                    form.regional_id = data.regional_id
                    form.provinsi_id = data.provinsi_id
                    form.cluster_id = data.cluster_id
                    form.min = data.min
                    form.max = data.max
                    form.status = data.status
                })
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                fetchById()
                
                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Konfigurasi Cluster`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
